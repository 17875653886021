import React from "react";
import { useStaticQuery, graphql } from "gatsby";

const Footer = ({ visible }) => {

    const data = useStaticQuery(graphql`
    query {allFile(filter: {name: {eq: "School_doodles_2"}}) {
        edges {
            node {
                publicURL
            }
        }
    }
    }
    `);
    const content =
         data.allFile.edges.map((o, ind) => {
             return (
                 <div key={ind}>
                     <img
                         className="footerLogo"
                         src={o.node.publicURL}
                         alt="logo"/>
                 </div>
             );});

    return (
        <footer>
            <div className="footerWrapper">
                <div className="divParentFooter">
                    <div className="divFooterItem">
                        Diese Seite wird betrieben von:
                        <br />
                        <a href="https://eduflux.de">
                            eduFlux GmbH
                        </a>
                    </div>
                    <div className="divFooterItem">
                        <a href="https://fehlzeitenerfassung.de/impressum">Impressum</a>
                        <br />
                        <a href="https://fehlzeitenerfassung.de/datenschutz">Datenschutzhinweise</a>
                    </div>
                    <div className="divFooterItem">
                        Support: 
                        <br />
                        <a href="mailto:hilfe@fehlzeitenerfassung.de">hilfe@fehlzeitenerfassung.de</a>
                    </div>
                </div>
                <div className="divFooterLogo">
                    {content}
                </div>
            </div>
        </footer>
    );
};

export default Footer;
