import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Logo from "./Logo";
import NestedList from "./NestedList";

import clsx from "clsx";
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import NavigationStyles from "./Navigation.module.css";

// Die Deklaration dieser Styles lässt sich nicht vermeiden, da der Wechsel des Hintergrundes
// bei der MUI-AppBar nur mittels JSX möglich ist.
const styles = {
    appBar: {
        background: "rgba(20.7, 29.4, 38.2, 0.0)",
        boxShadow: "none",
    }
};


export default function Navigation({visible, pages}) {
    const data = useStaticQuery(
        graphql`
            query {
                allFile(filter: {name: {eq: "icon"}}) {
                    edges {
                        node {
                            publicURL
                        }
                    }
                }
                allSitePage(sort: {fields: context___slug}) {
                    edges {
                        node {
                            context {
                                slug
                                index
                                name
                                parent {
                                    id
                                    index
                                    path
                                }
                                children {
                                    id
                                    index
                                    path
                                }
                            }
                            path
                            id
                        }
                    }
                }
            }
        `
    );
    const [state, setState] = useState({
        left: false,
    });
    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({ ...state, [anchor]: open });
    };

    const icon =
        data.allFile.edges.map((o, ind) => {
            return (
                <img key={ind}
                    src={o.node.publicURL}
                    style={{margin: "0.5em"}}
                    alt="logo"
                    className="iconLogo"/>
            );
        });
    let currentPage;
    if (pages.thisPage) {
        currentPage = pages.thisPage.url;
    }
    const items = data.allSitePage.edges;
    const list = (anchor) => (
        <div className={ clsx( 
            NavigationStyles.list, {
                [NavigationStyles.fullList]: anchor === 'top' || anchor === 'bottom',
            })}
            role="presentation"
            onKeyDown={toggleDrawer(anchor, false)}
            /*
             linkListVisible: ( visible === "no"),
             linkListInvisible: (visible === "yes")
             */
        >
            {/*Das Logo, das über der Linkliste angezeigt wird: */}
                <Logo onClick={toggleDrawer(anchor, false)} />
            <nav className="link">
                <List>
                    <NestedList items={items} currentPage={currentPage} />
                </List>
            </nav>
        </div>
    );

    return (
        <div>
            {['left'].map((anchor) => (
                <React.Fragment key={anchor}>
                    <AppBar 
                        className="appBar"
                        style={{
                            ...styles.appBar
                        }}
                    >
                        <Toolbar>
                            <Button className="menuButton" onClick={toggleDrawer(anchor, true)}>{icon} MENU</Button>
                            <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
                                {list(anchor)}
                            </Drawer>
                        </Toolbar>
                    </AppBar>
                </React.Fragment>
            ))}
        </div>
    );
};
