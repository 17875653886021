import React from "react";
import { useStaticQuery, graphql } from "gatsby";

export default function Logo({ onClick }) {

    const data = useStaticQuery(graphql`
    query {allFile(filter: {name: {eq: "icon"}}) {
        edges {
            node {
                publicURL
            }
        }
    }
    }
    `);
    const content =
         data.allFile.edges.map((o, ind) => {
             return (
                 <div key={ind} className="logoBox">
                     <button onClick={onClick} className="logoBox">
                         <img  src={o.node.publicURL}
                             alt="logo"
                             className="logo"/>
                     </button>
                 </div>
             );});


    return <> {content} </>;

}
