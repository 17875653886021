import React from "react";

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));

function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
}

function NestedItem({ item, allItems, currentPage }) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    // Zum Übergeben der previousLength:
    const itemLength = item.node.context.slug.split("/").length;
    const path = item.node.path;
    // legt den Seitennamen fest, der im Menü erscheint
    const pageName = item.node.context.name;
    let children = [];
    if (item.node.context.children) {
        item.node.context.children.forEach(child => children.push(allItems[child.index]));
    }

    const handleClick = () => {
        setOpen(!open);
    };

    let currentOpen = false;
    if ((currentPage === "/" && path === "/") || 
        (currentPage.match("/home") && path === "/") || 
        (currentPage.match(path) && path !== "/")) {
        currentOpen = true;
    }

    return (
        <>
            <ListItem button
                onClick={handleClick}
                key={item.node.id}
                className="linkList"
            >
                <ListItemLink
                    className="linkList"
                    href={path}
                >
                    {
                        // Die aufgerufene Seite und deren übergeordnete Seiten sollen im Menü
                        // orange statt grün sein
                        (currentPage && ((currentPage === "/" && path === "/") ||
                            (currentPage.match("/home/") && path === "/") || (
                                currentPage.match(path) && path !== "/"
                            ))) ? (
                                <ListItemText className="currentPage" primary={pageName} />
                            ) : (
                                <ListItemText primary={pageName} />
                            )
                    }
                </ListItemLink>
                {children[0] ? (currentOpen ? 
                    (<ExpandLess style={{ color: "#73e600" }} />) : 
                    (open ? <ExpandLess style={{ color: "#73e600" }} /> : <ExpandMore style={{ color: "#669900" }} />)) :
                    ""}
            </ListItem>
            { children[0] ? (currentOpen ? (
                <Collapse in={currentOpen} timeout="auto" unmountOnExit>
                    <List
                        className={classes.nested}
                        aria-labelledby="nested-list-subheader"
                    >
                        <NestedList 
                            items={children}
                            allItems={allItems}
                            currentPage={currentPage}
                            previousLength={itemLength}
                            key={item.node.path}
                        />
                    </List>
                    <Divider />
                </Collapse>
            ) : (
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <List
                        className={classes.nested}
                        aria-labelledby="nested-list-subheader"
                    >
                        <NestedList 
                            items={children}
                            allItems={allItems}
                            currentPage={currentPage}
                            previousLength={itemLength}
                            key={item.node.path}
                        />
                    </List>
                    <Divider />
                </Collapse>
            )
            ) : "" }
        </>
    );
}

export default function NestedList({ items, currentPage, allItems, previousLength }) {
    try {
        if (!allItems) {
            allItems = items;
        }
        return (
            <>
                {
                    items.map(function(item) { 
                        if (!item.node.context || !item.node.context.slug) return "";
                        const length = item.node.context.slug.split("/").length;
                        // Nur Hauptelemente sollen im Root-Menü erscheinen 
                        // ( z.B. slug.split("/") === "" "01.home" "" hat eine Länge von 3)
                        if (length === 3) {
                            return (
                                <NestedItem
                                    item={item}
                                    allItems={allItems}
                                    currentPage={currentPage}
                                    key={item.node.path}
                                />
                            );
                        } else if (previousLength) {
                            return (
                                <NestedItem
                                    item={item}
                                    allItems={allItems}
                                    currentPage={currentPage}
                                    key={item.node.path}
                                />
                            );
                        } else {
                            return "";
                        }
                    })
                }
            </>
        );
    } catch (err) {
        console.log(err);
    }
}
