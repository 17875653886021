import React from "react";

import Footer from "./Footer";
import Navigation from "./Navigation";

export default function Layout({ children, edges, pages }) {
    return (
        <div className="layout">
            <Navigation pages={pages ? pages : {}}/>
            <div className="templateLayout">
                {children}
            </div>
            <Footer 
                visible={ true } />
        </div>
    );
};
